import React, { Component, useContext } from 'react';
import { AppContainer } from '@prcosystems/react-common';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { onload } from 'redux-load-api';
import Helmet from 'react-helmet';
import cn from 'classnames';
import { Alert, Button, ProgressBar } from 'react-bootstrap';
import Info from '../components/Info';
import Notifs from '../components/Notifs';
import UserSnap from '../components/UserSnap';
import { AuthContext } from '../components/Auth';
import './App.css';

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.error(error);
    // eslint-disable-next-line no-console
    console.log(info);
    return { hasError: true, errorMessage: error.toString() };
  }

  dismissError = () => this.setState({ hasError: false, errorMessage: null });

  render() {
    return <App {...this.props} {...this.state} dismissError={this.dismissError} />;
  }
}

export function App(props) {
  const { info, loading, children, hasError, errorMessage, dismissError } = props;
  const { user } = useAuth0();
  const { logout } = useContext(AuthContext);

  return (
    <AppContainer onLogoutClick={logout} token={user} apiUrl="/">
      <Helmet title="Sales Portal" />
      <UserSnap />

      <div className="app-content">
        <div className={cn('container-fluid', 'page-container')}>
          {info && info.message && (
            <div className="container">
              <Info className="info" />
            </div>
          )}
          {loading ? <ProgressBar now={100} active={true} /> : null}
          {hasError ? (
            <Alert bsStyle="danger" onDismiss={dismissError} style={{ width: 500, margin: 'auto' }}>
              <h4>The application has experienced an error</h4>
              {errorMessage}
              <p>You may need to refresh the application.</p>
              <Button bsStyle="danger" onClick={() => document.location.reload()}>
                Take action
              </Button>
              <span> or </span>
              <Button onClick={dismissError}>Dismiss</Button>
            </Alert>
          ) : (
            children
          )}
          <div className="container">
            <Notifs className="notifications" namespace="global" />
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

App.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  dismissError: PropTypes.func.isRequired,
};

App.contextTypes = {
  store: PropTypes.object.isRequired,
};

App.defaultProps = {
  user: null,
  loading: false,
  hasError: false,
  errorMessage: null,
};

export default onload(() => {})(
  connect(state => ({
    info: state.info,
    notifs: state.notifs,
    loading: state.page.loading,
  }))(AppErrorBoundary),
);
